/* stylelint-disable no-descending-specificity */
.nav-bar {
  background: #181818;
  width: 8rem;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 50rem;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 2.4rem;
      height: auto;

      &.sub-logo {
        width: 5rem;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 21rem;
    top: 50%;
    margin-top: -12rem;
    width: 100%;

    a {
      font-size: 2.2rem;
      color: #4d4d4e;
      display: block;
      height: 5.1rem;
      line-height: 5.1rem;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &::after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }

      &:first-child {
        &::after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &::after {
        content: 'ABOUT';
      }
    }

    a.portfolio-link {
      &::after {
        content: 'PORTFOLIO';
      }
    }

    a.contact-link {
      &::after {
        content: 'CONTACT';
      }
    }

    a.active {
      svg {
        color: #ffd700;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;

        &:hover {
          color: #ffd700;
        }
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */
