.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.top-tags {
  bottom: auto;
  top: 3.5rem;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 12rem;
  font-size: 1.8rem;
  font-family: 'La Belle Aurore', cursive;
}

.bottom-tag-html {
  margin-left: -2rem;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-width: 56.6rem;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.contact-page,
.container.about-page,
.container.portfolio-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;

    h1 {
      font-size: 5.3rem;
      font-family: 'Coolvetica', sans-serif;
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 4rem;
      left: -1rem;

      &::before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 1.8rem;
        position: absolute;
        margin-top: -1rem;
        left: -10px;
        opacity: 0.6;
        line-height: 1.8rem;
      }

      &::after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 1.8rem;
        line-height: 1.8rem;
        position: absolute;
        left: -3rem;
        bottom: -2rem;
        margin-left: 2rem;
        opacity: 0.6;
      }
    }

    /* stylelint-disable csstree/validator */

    p {
      font-size: 1.3rem;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;

      /* stylelint-enable csstree/validator */

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
